/* eslint-disable prettier/prettier */
import React from "react";
import Card from "react-bootstrap/Card";

const Data = ({ data }) => {
    const Data = (
        <div>
            <p style={{ fontSize: "18px", color: "#000" }}>
                জাতির জনক বঙ্গবন্ধু শেখ মুজিবুর রহমানের জন্মশতবার্ষিকী উপলক্ষে
                ২০২০ ও ২০২১ সালকে ‘মুজিব বর্ষ’ হিসেবে উদযাপনের সিদ্ধান্ত নিয়েছে
                সরকার। এই উপলক্ষে ২০২০ সালের ১৭ মার্চ থেকে ২০২১ সালের ১৬
                ডিসেম্বর পর্যন্ত বছরব্যাপী নানা অনুষ্ঠান ও কর্মসূচী পালন করা হবে
                দেশব্যাপী। (করোনাভাইরাসের কারণে গ্রহণ করা কর্মসূচিগুলো নির্ধারিত
                সময়ে যথাযথভাবে করতে না পারায় মুজিববর্ষের মেয়াদ প্রায় ৯ মাস
                বাড়ানো হয়েছে। এ সময়কাল ২০২০ সালের ১৭ মার্চ থেকে বাড়িয়ে ২০২১
                সালের ১৬ ডিসেম্বর পর্যন্ত করা হয়েছে।) উল্লেখ্য, ২০২০ সালে পূর্ণ
                হবে বাংলাদেশের স্বাধীনতার মহান স্থপতি জাতির পিতা বঙ্গবন্ধু শেখ
                মুুজিবুর রহমানের জন্মের শত বছর। আর ২০২১ সাল হবে বাংলাদেশের
                স্বাধীনতার সুবর্ণজয়ন্তী বছর।
            </p>
            {data.map((item, i) => {
                return (
                    <div
                        className='col-lg-4 col-md-6 col-sm-12'
                        key={i}
                        style={{ marginBottom: "10px" }}
                    >
                        <a href={item.notice} download>
                            <span style={{ fontSize: "16px", color: "#000" }}>
                                <b style={{ fontSize: "30px" }}>•</b> {item.title}
                            </span>
                        </a>
                    </div>
                );
            })}
        </div>
    );
    return Data;
};

export default Data;
